import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Helmet } from 'react-helmet';
import { About } from "./components/about";
import { Services } from "./components/services";
import { Programs } from "./components/programs";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {/* SEO Metadata */}
      <Helmet>
        <title>DJ live song | Wedding | Birthday | Party</title>
        <meta name="description" content="Vancouver | Burnaby | Langley | Surrey Live DJ. Enjoy live song at your party!" />
        <meta name="keywords" content="Vancouver Live DJ, Wedding with live songs, Wedding with live music, live song at birthday party, live music at birthday party" />
        <meta name="author" content="DJ Ravshan" />
        <meta property="og:title" content="Top International Songs Playlist" />
        <meta property="og:description" content="International songs, Vancouver Live DJ, Arabic | Persian | Iran | Turkish | International songs, classical music, popular tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com" />
        <meta property="og:image" content="https://djravshan.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Wedding | Birthday party with live Arabic | Persian | Iran | Turkish | International songs" />
        <meta name="twitter:description" content="Wedding | Birthday party with live Arabic | Persian | Iran | Turkish | International songs" />
        <meta name="twitter:image" content="https://yourwebsite.com/images/international-songs-cover.jpg" />
      </Helmet>

      <Navigation />
      <Header data={landingPageData.Header} />
      {/*<Features data={landingPageData.Features} />*/}
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Programs data={landingPageData.Programs} />
      {/*<Testimonials data={landingPageData.Testimonials} />*/}
      {/*<Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
