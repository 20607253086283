import { Image } from "./image";
import React from "react";

export const Programs = (props) => {
  return (
    <div id="programs" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Programs</h2>
          <p>
            If you provide your playlist or songs of your favor, we play them. Here are some samples most frequently customer requested.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6">
                    <a href={d.link} 
                        className="program-link"
                        target="_blank">
                      {d.title}
                    </a>
                    <div style={{marginBottom: "20px"}}></div>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div style={{marginBottom: "20px"}}></div>
      </div>
    </div>
  );
};
