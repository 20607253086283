import { Helmet } from 'react-helmet';

const TurkishSongs = () => {
  const songs = [
    "Ah Istanbul.Sezan Aksu",
    "Bag’dat",
    "Gonul Sayfam.Keyhan",
    "Zalim. Sezan Aksu",
    "Can Bedenden Cikmayinca",
    "Allah Allah.(Ibrahim Tatlises)",
    "Asiksen",
    "Gel gel",
    "Leylem Ley",
    "Geri Don.Sezan Aksu",
    "Kis kis Tarkan",
    "Padishah Sibel Can",
  ];

  return (
    <div style={styles.container}>

      {/* SEO Metadata */}
      <Helmet>
        <title>Top Turkish Songs Playlist | Wedding | Birthday</title>
        <meta name="description" content="Vancouver Live DJ. Mostly played Turkish songs. Enjoy live song at your party!" />
        <meta name="keywords" content="Turkish songs, Vancouver Live DJ, Mostly played Turkish songs, Turkish classical music, popular Turkish tracks, Live music at party, live songs at wedding, live song at birthday party" />
        <meta name="author" content="DJ Ravshan" />
        <meta property="og:title" content="Top Turkish Songs Playlist" />
        <meta property="og:description" content="Turkish songs, Vancouver Live DJ, Mostly played Turkish songs, Turkish classical music, popular Turkish tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com/mostly-played-turkish-songs" />
        <meta property="og:image" content="https://yourwebsite.com/images/turkish-songs-cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top Turkish Songs Playlist" />
        <meta name="twitter:description" content="Discover a playlist of top Turkish songs." />
        <meta name="twitter:image" content="https://yourwebsite.com/images/turkish-songs-cover.jpg" />
      </Helmet>

      <h1 style={styles.title}>Mostly played Turkish songs</h1>
      <ol style={styles.list}>
        {songs.map((song, index) => (
          <li key={index}>{song}</li>
        ))}
      </ol>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '0',
    listStylePosition: 'inside',
  },
};

export default TurkishSongs;