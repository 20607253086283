import { Helmet } from 'react-helmet';

const IranSongs = () => {
  const songs = [
    "Motasel  Mohsen Chavoshi",
    "Behet ghol Midam",
    "Harfe Na Nagofteh.Omid",
    "Faramusham Nakon.Moen",
    "Dooset Daram",
    "Alijaneb.Evan Band",
    "Sasy.Doctor",
    "Alireza Talischi-Paghadam",
    "Jamal Jamaloo",
    "Fateneh-Namehrab",
  ];

  return (
    <div style={styles.container}>

      {/* SEO Metadata */}
      <Helmet>
        <title>Top Iran, Persian Songs Playlist | Wedding | Birthday</title>
        <meta name="description" content="Vancouver Live DJ. Mostly played Iran songs. Enjoy live song at your party!" />
        <meta name="keywords" content="Iranian songs, Vancouver Live DJ, Mostly played Iran songs, Persian classical music, popular Iran tracks, Live music at party, live songs at wedding, live song at birthday party" />
        <meta name="author" content="DJ Ravshan" />
        <meta property="og:title" content="Top Iran Songs Playlist" />
        <meta property="og:description" content="Persian songs, Vancouver Live DJ, Mostly played Iran songs, Iran classical music, popular Iran tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com/mostly-played-iran-songs" />
        <meta property="og:image" content="https://yourwebsite.com/images/iran-songs-cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top Persian Songs Playlist" />
        <meta name="twitter:description" content="Discover a playlist of top Iran songs." />
        <meta name="twitter:image" content="https://yourwebsite.com/images/iran-songs-cover.jpg" />
      </Helmet>

      <h1 style={styles.title}>Mostly played Iran songs</h1>
      <ol style={styles.list}>
        {songs.map((song, index) => (
          <li key={index}>{song}</li>
        ))}
      </ol>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '0',
    listStylePosition: 'inside',
  },
};

export default IranSongs;