import { Helmet } from 'react-helmet';

const ArabicSongs = () => {
  const songs = [
    "Tamally Maak.Amr Diab",
    "Qusad Einy",
    "Khaleek Fekerni",
    "We Ghalawtek",
    "Habibi Ya nour el ain",
    "Alabina. Yalla bina Yalla",
    "Salma Ya Salama",
    "Helwa Ya Baladi.Dalida",
    "La.Dystinct",
    "Ya Ghayeb.Fadel Chaker",
    "Abdul Khader.Cheb Khaled",
    "DIDI. Khaled",
    "Sidi Mansour.Saber Rebai",
    "Sabry Aalil. Sherine",
    "Ya Rayah.Rachid Taha",
    "Inta Maalem. Saad Lamjarred",
    "Nari Nari Hisham Abbas",
    "Wana Wana Amel",
    "Agoul Ahwak.Haifa Wahbe",
    "Aziza Arabic classical",
    "Ahwak Arabic classical music",
    "El Watar El Hassas.Sherine",
    "Moroccan Chabi",
    "Malubiya Malubiya",
    "Betmoun Elisa",
    "Inta Iyh Nancy Ajram",
    "Aicha.Khaled",
    "Bint al Shalabiya",
    "Mabrouk.",
    "Stanga.Sagi Abitbul",
    "Ya Zahratan Hayali",
    "Youm wara youm.Chab Mami",
    "Moroccan Zerda Dj",
    "Rami Gamal.Baeit Akhaf",
    "Zahra Arabic classical",
    "Lamouni Elli Gharoi Meni",
    "Al Garqan.Hussain Al Jassmi",
    "Enta omri.Um Kulthum",
    "El Youm El Halweda.Ahmed Saad",
    "Rouh.Zohair Bahaoui",
  ];

  return (
    <div style={styles.container}>

      {/* SEO Metadata */}
      <Helmet>
        <title>Top Arabic Songs Playlist | Wedding | Birthday</title>
        <meta name="description" content="Vancouver Live DJ. Mostly played Arabic songs. Enjoy live song at your party!" />
        <meta name="keywords" content="Arabic songs, Vancouver Live DJ, Mostly played Arabic songs, Arabic classical music, popular Arabic tracks, Live music at party, live songs at wedding, live song at birthday party" />
        <meta name="author" content="DJ Ravshan" />
        <meta property="og:title" content="Top Arabic Songs Playlist" />
        <meta property="og:description" content="Arabic songs, Vancouver Live DJ, Mostly played Arabic songs, Arabic classical music, popular Arabic tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com/mostly-played-arabic-songs" />
        <meta property="og:image" content="https://yourwebsite.com/images/arabic-songs-cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top Arabic Songs Playlist" />
        <meta name="twitter:description" content="Discover a playlist of top Arabic songs." />
        <meta name="twitter:image" content="https://yourwebsite.com/images/arabic-songs-cover.jpg" />
      </Helmet>

      <h1 style={styles.title}>Mostly played Arabic songs</h1>
      <ol style={styles.list}>
        {songs.map((song, index) => (
          <li key={index}>{song}</li>
        ))}
      </ol>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '0',
    listStylePosition: 'inside',
  },
};

export default ArabicSongs;