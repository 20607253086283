import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import ArabicSongs from './programs/ArabicSongs';
import IranSongs from './programs/IranSongs';
import TurkishSongs from './programs/TurkishSongs';
import InternationalSongs from './programs/InternationalSongs';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
          <Route path="mostly-played-arabic-songs" element={<ArabicSongs />} />
          <Route path="mostly-played-iran-songs" element={<IranSongs />} />
          <Route path="mostly-played-turkish-songs" element={<TurkishSongs />} />
          <Route path="mostly-played-international-songs" element={<InternationalSongs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
