import { Helmet } from 'react-helmet';

const InternationalSongs = () => {
  const songs = [
    "Sting.Desert rose",
    "Sting.Fragile",
    "Makeba",
    "Phil Collins Tonight",
    "Jerusalem",
    "Julio Iglesias Historian de Amour",
    "Calm down.Selena Gomez",
    "Memories.Maroon5",
    "Dance Monkey",
    "Levitating Dua Lipa",
    "Dj Pilligrim",
    "Tunga Tunga Baje",
    "Boney M. Rasputin",
    "La Vie Populaire",
    "When I Dream Marc Antony",
    "Jah Khaleb Leyla",
    "Quizas Quizas",
    "I just call to say. Stevie Wonder",
  ];

  return (
    <div style={styles.container}>

      {/* SEO Metadata */}
      <Helmet>
        <title>Top International Songs Playlist | Wedding | Birthday</title>
        <meta name="description" content="Vancouver Live DJ. Mostly played International songs. Enjoy live song at your party!" />
        <meta name="keywords" content="International songs, Vancouver Live DJ, Mostly played International songs, International classical music, popular International tracks, Live music at party, live songs at wedding, live song at birthday party" />
        <meta name="author" content="DJ Ravshan" />
        <meta property="og:title" content="Top International Songs Playlist" />
        <meta property="og:description" content="International songs, Vancouver Live DJ, Mostly played International songs, International classical music, popular International tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com/mostly-played-international-songs" />
        <meta property="og:image" content="https://yourwebsite.com/images/international-songs-cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top International Songs Playlist" />
        <meta name="twitter:description" content="Discover a playlist of top International songs." />
        <meta name="twitter:image" content="https://yourwebsite.com/images/international-songs-cover.jpg" />
      </Helmet>

      <h1 style={styles.title}>Mostly played TurInternationalkish songs</h1>
      <ol style={styles.list}>
        {songs.map((song, index) => (
          <li key={index}>{song}</li>
        ))}
      </ol>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '0',
    listStylePosition: 'inside',
  },
};

export default InternationalSongs;