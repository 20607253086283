import { useState } from "react";
import React from "react";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  isMailBeingSent: false
};
export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const url = 'https://iuphroyi75.execute-api.ca-central-1.amazonaws.com/default/partyWithQanunAppSendToTelegram';
	// this is just random code to stop any bots sending requests
  const apiKey = '1AbDMwE6St4qMLO4lbhkk7QWty1pQfoP7SpZEvWb';

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, phone, message);
    
    const data = '*** Customer request ***\n'+ 'Name: ' + name + '\n'		
      + 'Phone: ' + phone + '\n'
      + 'Email: ' + email + '\n'
			+ 'Description: ' + message;

    initialState.isMailBeingSent = true;

    await fetch(url, {
      method: 'POST',
      headers: { 'x-api-key': apiKey },
      body: JSON.stringify(data),
    }).then(response => {
      if (!response.ok) {
        alert("Error occurred, please try again.");
      } else {
        let sendSuccessElement = document.getElementById("sent_success");
        sendSuccessElement.style.display = "block";
        setTimeout(function () {
          sendSuccessElement.style.display = "none";
        }, 5000);
      }
    });
    initialState.isMailBeingSent = false;
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Request a quote</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    Your name:
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    Your email
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    Your phone
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone number"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>

          {
            initialState.isMailBeingSent ? 
            (<div className="loader-overlay">
              <div className="card" style={{width: '100%'}}>
                <div className="card-body">
                  <div className="text-center">
                    <h6>Sending request...</h6>
                  </div>
                  <progress value={null} style={{width: '100%'}}/>
                </div>
              </div>
            </div>)
              : ""
          }
          
          <div className="row padding-fifteen collapsed" id="sent_success" style={{display: 'none'}}>
            <div className="col-md-12 alert alert-success" id="alert">
                Your request has been sent successfully. We will contact you soon.
            </div>
          </div>

          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? <a style={{color: "lightgrey"}} href='tel:'{...props.data.phone}>{props.data.phone}</a> : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? <a style={{color: "lightgrey"}} href='mailto:'{...props.data.email}>{props.data.email}</a> : "loading"}
              </p>
            </div>
          </div>
          {/*<div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
          Copyright © 2024 DJ Ravshan. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
